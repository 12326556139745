import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MentionMeService } from '@x/common/referral/mention-me/services/mention-me.service';
import { ScriptLoaderScript } from '@x/common/script-loader/script-loader.service';
import { IShopAuthUser } from '@x/ecommerce/shop-client';

@Component({
  selector: 'x-mm-dashboard',
  templateUrl: './mm-dashboard.component.html',
  styleUrls: ['./mm-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmDashboardComponent implements OnInit, OnDestroy {
  user: IShopAuthUser = this.route.snapshot.data.myAccountUser;

  params: Params = {
    email: this.user.email,
    firstname: this.user.firstName,
    surname: this.user.lastName,
    situation: 'dashboard',
  };

  loadScript: ScriptLoaderScript | undefined;

  constructor(private mentionMeService: MentionMeService, private route: ActivatedRoute) {}

  ngOnDestroy(): void {
    if (!this.loadScript) return;

    const key = `embed${this.params.situation}`;
    this.mentionMeService.unloadScript(this.loadScript.src, key);
  }

  async ngOnInit() {
    if (!this.mentionMeService.isConfigured) return;

    this.loadScript = await this.mentionMeService.loadScript('dashboard', this.params);
  }
}
